import { render, staticRenderFns } from "./a-link-button.vue?vue&type=template&id=0be3d330&scoped=true&"
import script from "./a-link-button.vue?vue&type=script&lang=ts&"
export * from "./a-link-button.vue?vue&type=script&lang=ts&"
import style0 from "./a-link-button.vue?vue&type=style&index=0&id=0be3d330&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be3d330",
  null
  
)

export default component.exports