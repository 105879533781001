/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTeam = /* GraphQL */ `query GetTeam($id: ID!) {
  getTeam(id: $id) {
    botUserId
    id
    teamId
    installed
    ticketNumber
    planSubscriptionId
    planSubscription {
      id
      planId
      plan {
        id
        name
        formsLimit
        customFieldsLimit
        code
        createdAt
        updatedAt
        __typename
      }
      internalDescription
      billingPeriod
      billingPeriodPriceUsd
      billingPeriodPriceLabel
      isCustom
      isFree
      isTrial
      minAgents
      public
      createdAt
      updatedAt
      __typename
    }
    lastTrialDay
    iiZendesk
    agents
    autoCreateChannels
    features
    planOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const listTeams = /* GraphQL */ `query ListTeams(
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      botUserId
      id
      teamId
      installed
      ticketNumber
      planSubscriptionId
      planSubscription {
        id
        planId
        internalDescription
        billingPeriod
        billingPeriodPriceUsd
        billingPeriodPriceLabel
        isCustom
        isFree
        isTrial
        minAgents
        public
        createdAt
        updatedAt
        __typename
      }
      lastTrialDay
      iiZendesk
      agents
      autoCreateChannels
      features
      planOffer
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const getQueue = /* GraphQL */ `query GetQueue($id: ID!) {
  getQueue(id: $id) {
    format
    id
    integrationChannels
    name
    teamId
    features
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetQueueQueryVariables, APITypes.GetQueueQuery>;
export const listQueues = /* GraphQL */ `query ListQueues(
  $filter: ModelQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      format
      id
      integrationChannels
      name
      teamId
      features
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQueuesQueryVariables,
  APITypes.ListQueuesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    agentQueues
    id
    scope
    teamId
    token_type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      agentQueues
      id
      scope
      teamId
      token_type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getTicket = /* GraphQL */ `query GetTicket($id: ID!) {
  getTicket(id: $id) {
    archived
    assignee
    createdAt
    createdBy
    description
    formId
    form {
      id
      createdAt
      createdBy
      displayChannels
      hidden
      fields
      name
      enabledInDm
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      teamId
      emailPartitionKey
      email
      updatedAt
      updatedBy
      __typename
    }
    formValues
    id
    messageResponderChannel
    messageResponderId
    messageRequesterChannel
    messageRequesterId
    priority
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      features
      createdAt
      updatedAt
      __typename
    }
    requesterId
    requester {
      agentQueues
      id
      scope
      teamId
      token_type
      createdAt
      updatedAt
      __typename
    }
    status
    team_archived_hash
    teamTicketId
    teamId
    team {
      botUserId
      id
      teamId
      installed
      ticketNumber
      planSubscriptionId
      planSubscription {
        id
        planId
        internalDescription
        billingPeriod
        billingPeriodPriceUsd
        billingPeriodPriceLabel
        isCustom
        isFree
        isTrial
        minAgents
        public
        createdAt
        updatedAt
        __typename
      }
      lastTrialDay
      iiZendesk
      agents
      autoCreateChannels
      features
      planOffer
      createdAt
      updatedAt
      __typename
    }
    teamName
    ticketNumber
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTicketQueryVariables, APITypes.GetTicketQuery>;
export const listTickets = /* GraphQL */ `query ListTickets(
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTicketsQueryVariables,
  APITypes.ListTicketsQuery
>;
export const getForm = /* GraphQL */ `query GetForm($id: ID!) {
  getForm(id: $id) {
    id
    createdAt
    createdBy
    displayChannels
    hidden
    fields
    name
    enabledInDm
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      features
      createdAt
      updatedAt
      __typename
    }
    teamId
    emailPartitionKey
    email
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormQueryVariables, APITypes.GetFormQuery>;
export const listForms = /* GraphQL */ `query ListForms(
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      createdBy
      displayChannels
      hidden
      fields
      name
      enabledInDm
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      teamId
      emailPartitionKey
      email
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFormsQueryVariables, APITypes.ListFormsQuery>;
export const getField = /* GraphQL */ `query GetField($id: ID!) {
  getField(id: $id) {
    id
    createdAt
    createdBy
    dataSource
    dataSourceType
    label
    name
    teamId
    uiType
    multi
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFieldQueryVariables, APITypes.GetFieldQuery>;
export const listFields = /* GraphQL */ `query ListFields(
  $filter: ModelFieldFilterInput
  $limit: Int
  $nextToken: String
) {
  listFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      createdBy
      dataSource
      dataSourceType
      label
      name
      teamId
      uiType
      multi
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFieldsQueryVariables,
  APITypes.ListFieldsQuery
>;
export const getPlan = /* GraphQL */ `query GetPlan($id: ID!) {
  getPlan(id: $id) {
    id
    name
    formsLimit
    customFieldsLimit
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPlanQueryVariables, APITypes.GetPlanQuery>;
export const listPlans = /* GraphQL */ `query ListPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      formsLimit
      customFieldsLimit
      code
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPlansQueryVariables, APITypes.ListPlansQuery>;
export const getPlanSubscription = /* GraphQL */ `query GetPlanSubscription($id: ID!) {
  getPlanSubscription(id: $id) {
    id
    planId
    plan {
      id
      name
      formsLimit
      customFieldsLimit
      code
      createdAt
      updatedAt
      __typename
    }
    internalDescription
    billingPeriod
    billingPeriodPriceUsd
    billingPeriodPriceLabel
    isCustom
    isFree
    isTrial
    minAgents
    public
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlanSubscriptionQueryVariables,
  APITypes.GetPlanSubscriptionQuery
>;
export const listPlanSubscriptions = /* GraphQL */ `query ListPlanSubscriptions(
  $filter: ModelPlanSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlanSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      planId
      plan {
        id
        name
        formsLimit
        customFieldsLimit
        code
        createdAt
        updatedAt
        __typename
      }
      internalDescription
      billingPeriod
      billingPeriodPriceUsd
      billingPeriodPriceLabel
      isCustom
      isFree
      isTrial
      minAgents
      public
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlanSubscriptionsQueryVariables,
  APITypes.ListPlanSubscriptionsQuery
>;
export const listQueuesByTeamId = /* GraphQL */ `query ListQueuesByTeamId(
  $teamId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listQueuesByTeamId(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      format
      id
      integrationChannels
      name
      teamId
      features
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQueuesByTeamIdQueryVariables,
  APITypes.ListQueuesByTeamIdQuery
>;
export const UsersByTeam = /* GraphQL */ `query UsersByTeam(
  $teamId: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  UsersByTeam(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      agentQueues
      id
      scope
      teamId
      token_type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByTeamQueryVariables,
  APITypes.UsersByTeamQuery
>;
export const TicketBymessageResponderId = /* GraphQL */ `query TicketBymessageResponderId(
  $teamId: ID
  $messageResponderId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketBymessageResponderId(
    teamId: $teamId
    messageResponderId: $messageResponderId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketBymessageResponderIdQueryVariables,
  APITypes.TicketBymessageResponderIdQuery
>;
export const TicketBymessageRequesterId = /* GraphQL */ `query TicketBymessageRequesterId(
  $teamId: ID
  $messageRequesterId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketBymessageRequesterId(
    teamId: $teamId
    messageRequesterId: $messageRequesterId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketBymessageRequesterIdQueryVariables,
  APITypes.TicketBymessageRequesterIdQuery
>;
export const TicketByTeam = /* GraphQL */ `query TicketByTeam(
  $teamId: ID
  $createdAt: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketByTeam(
    teamId: $teamId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketByTeamQueryVariables,
  APITypes.TicketByTeamQuery
>;
export const TicketByAssignee = /* GraphQL */ `query TicketByAssignee(
  $assignee: String
  $sortDirection: ModelSortDirection
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketByAssignee(
    assignee: $assignee
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketByAssigneeQueryVariables,
  APITypes.TicketByAssigneeQuery
>;
export const TicketsByQueue = /* GraphQL */ `query TicketsByQueue(
  $queueId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketsByQueue(
    queueId: $queueId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketsByQueueQueryVariables,
  APITypes.TicketsByQueueQuery
>;
export const ticketByTicketNumber = /* GraphQL */ `query TicketByTicketNumber(
  $teamTicketId: String
  $sortDirection: ModelSortDirection
  $filter: ModelTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  ticketByTicketNumber(
    teamTicketId: $teamTicketId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      archived
      assignee
      createdAt
      createdBy
      description
      formId
      form {
        id
        createdAt
        createdBy
        displayChannels
        hidden
        fields
        name
        enabledInDm
        queueId
        teamId
        emailPartitionKey
        email
        updatedAt
        updatedBy
        __typename
      }
      formValues
      id
      messageResponderChannel
      messageResponderId
      messageRequesterChannel
      messageRequesterId
      priority
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      requesterId
      requester {
        agentQueues
        id
        scope
        teamId
        token_type
        createdAt
        updatedAt
        __typename
      }
      status
      team_archived_hash
      teamTicketId
      teamId
      team {
        botUserId
        id
        teamId
        installed
        ticketNumber
        planSubscriptionId
        lastTrialDay
        iiZendesk
        agents
        autoCreateChannels
        features
        planOffer
        createdAt
        updatedAt
        __typename
      }
      teamName
      ticketNumber
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketByTicketNumberQueryVariables,
  APITypes.TicketByTicketNumberQuery
>;
export const listFormsByTeamId = /* GraphQL */ `query ListFormsByTeamId(
  $teamId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormsByTeamId(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      createdBy
      displayChannels
      hidden
      fields
      name
      enabledInDm
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        features
        createdAt
        updatedAt
        __typename
      }
      teamId
      emailPartitionKey
      email
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormsByTeamIdQueryVariables,
  APITypes.ListFormsByTeamIdQuery
>;
export const listFieldsByTeamId = /* GraphQL */ `query ListFieldsByTeamId(
  $teamId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelFieldFilterInput
  $limit: Int
  $nextToken: String
) {
  listFieldsByTeamId(
    teamId: $teamId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      createdBy
      dataSource
      dataSourceType
      label
      name
      teamId
      uiType
      multi
      updatedAt
      updatedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFieldsByTeamIdQueryVariables,
  APITypes.ListFieldsByTeamIdQuery
>;
