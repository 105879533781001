import { ComputedRef, InjectionKey, Ref } from "@vue/composition-api";
import { IdLabel } from "@/core/models/IdLabel";
import { IdLabelIcon } from "@/core/models/IdLabelIcon";
import { Column } from "./models/Column";

export const STATUSES_LOOKUP_KEY = Symbol(
  "STATUSES_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabel[]>>;

export const TAGS_LOOKUP_KEY = Symbol("TAGS_LOOKUP_KEY") as InjectionKey<
  Ref<IdLabel[]>
>;

export const PRIORITIES_LOOKUP_KEY = Symbol(
  "PRIORITIES_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabel[]>>;

export const FORMS_LOOKUP_KEY = Symbol("FORMS_LOOKUP_KEY") as InjectionKey<
  Ref<IdLabel[] | undefined>
>;

export const ASSIGNEES_LOOKUP_KEY = Symbol(
  "ASSIGNEES_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabel[] | undefined>>;

export const REQUESTER_CHANNELS_LOOKUP_KEY = Symbol(
  "REQUESTER_CHANNELS_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabelIcon[] | undefined>>;

export const RESPONDER_CHANNELS_LOOKUP_KEY = Symbol(
  "RESPONDER_CHANNELS_LOOKUP_KEY"
) as InjectionKey<Ref<{ group: string; items: IdLabel[] }[] | undefined>>;

export const QUEUES_LOOKUP_KEY = Symbol("QUEUES_LOOKUP_KEY") as InjectionKey<
  Ref<{ group: string; items: IdLabel[] }[] | undefined>
>;

export const EDIT_COLUMNS = Symbol("EDIT_COLUMNS") as InjectionKey<
  ComputedRef<Column[]>
>;

export const STATUSES_EDIT_LOOKUP_KEY = Symbol(
  "STATUSES_EDIT_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabel[]>>;

export const ASSIGNEES_EDIT_LOOKUP_KEY = Symbol(
  "ASSIGNEES_EDIT_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabel[]>>;

export const TAGS_EDIT_LOOKUP_KEY = Symbol(
  "TAGS_EDIT_LOOKUP_KEY"
) as InjectionKey<Ref<IdLabel[]>>;

export const DEFAULT_AGENT_REPLIES_VISIBILITY_KEY = Symbol(
  "DEFAULT_AGENT_REPLIES_VISIBILITY_KEY"
) as InjectionKey<Ref<"public" | "private">>;
