import { fetchApiSlack, fetchApiSlackV2 } from "@/helpers/api.slack";
import { ChannelSlackModel } from "@/models/ChannelModel";
import axios from "axios";
import qs from "qs";

export interface ChannelResponse {
  ok: boolean;
  channel: ChannelSlackModel;
  error?: string;
}

interface SlackMetadata<Payload = Record<string, unknown>> {
  event_type: "suptask-web-reply";
  event_payload: Payload;
}

interface PostMessageResponse {
  ok: boolean;
  channel: string;
  ts: string;
  message: unknown;
}

export default class ChannelRepo {
  /**
   * Use this directly method with caution!
   * Usually you should not use it directly, but instead use slackChannels store to access channels, which includes such aspects like eventual channels caching
   */
  public static async get(
    token: string,
    types: ("public_channel" | "private_channel")[] = [
      "public_channel",
      "private_channel"
    ],
    intermediateResultCallback?: (
      items: ChannelSlackModel[],
      allLoaded: boolean
    ) => void
  ): Promise<ChannelSlackModel[]> {
    const slackChannels = await fetchApiSlackV2<ChannelSlackModel>({
      // See Slack documentation: https://api.slack.com/methods/conversations.list
      url: "https://slack.com/api/conversations.list",
      token,
      data: {
        types: types.length == 0 ? undefined : types.join(","),
        // eslint-disable-next-line
        exclude_archived: true
      },
      itemsField: "channels",
      limit: 1000,
      totalLimit: 15000,
      intermediateResultCallback
    });

    return slackChannels;
  }

  /**
   * Use this directly method with caution!
   * Usually you should not use it directly, but instead use slackChannels store to create channels, which includes such aspects like eventual channels caching
   */
  public static async post(
    token: string,
    name: string,
    isPrivate = false
  ): Promise<ChannelResponse> {
    const data = {
      token,
      name,
      // eslint-disable-next-line
      is_private: isPrivate
    };
    const response = await axios.post<ChannelResponse>(
      // See Slack documentation: https://api.slack.com/methods/conversations.create
      "https://slack.com/api/conversations.create",
      qs.stringify(data)
    );

    return response.data;
  }

  public static async invite(
    token: string,
    channelId: string,
    userIds: string[]
  ): Promise<ChannelResponse> {
    const data = {
      token,
      channel: channelId,
      users: userIds.join(",")
    };
    const response = await axios.post<ChannelResponse>(
      // See Slack documentation: https://api.slack.com/methods/conversations.invite
      "https://slack.com/api/conversations.invite",
      qs.stringify(data)
    );

    return response.data;
  }

  /**
   * Get ids of all members of the channel
   */
  public static async memberIds(
    token: string,
    channelId: string
  ): Promise<string[]> {
    const slackMembers = await fetchApiSlack<string>(
      // See Slack documentation: https://api.slack.com/methods/conversations.members
      "https://slack.com/api/conversations.members",
      token,
      {
        token,
        channel: channelId
      },
      "members"
    );

    return slackMembers;
  }

  /**
   * Post message to a channel
   */
  public static async postMessage(
    token: string,
    channelId: string,
    text: string,
    /**
     * Post in thread with this id
     */
    threadTs?: string,
    eventPayload?: Record<string, unknown>
  ): Promise<PostMessageResponse> {
    const metadata: SlackMetadata = {
      event_type: "suptask-web-reply",
      event_payload: eventPayload ?? {}
    };
    const data = {
      token,
      channel: channelId,
      text,
      thread_ts: threadTs,
      metadata: JSON.stringify(metadata)
    };
    const response = await axios.post<PostMessageResponse>(
      // See Slack documentation: https://api.slack.com/methods/chat.postMessage
      "https://slack.com/api/chat.postMessage",
      qs.stringify(data)
    );

    return response.data;
  }

  /**
   * Adds a reaction to an item.
   * Requires reactions:write scope which is not included in Suptask app User scopes yet (2025-03-31)
   */
  public static async addReaction(
    token: string,
    channelId: string,
    timestamp: string,
    reaction: string
  ): Promise<void> {
    const data = {
      token,
      channel: channelId,
      timestamp,
      name: reaction
    };
    await axios.post(
      // See Slack documentation: https://api.slack.com/methods/reactions.add
      "https://slack.com/api/reactions.add",
      qs.stringify(data)
    );
  }
}
