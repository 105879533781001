/**
 * In this module we import icons from different 'style' packages, e,g, 'solid', 'regular', 'brand'.
 * By default 'solid' is used, so specifiying an icon in <font-awesome-icon> componennt only by name will attempt to use the 'solid' icon:
 *  <font-awesome-icon icon="question-circle" />
 * In order to use an icon from annother 'style' package specify the style explicitly:
 *  <font-awesome-icon icon="fa-regular fa-question-circle" />
 * Note that in this case it's required to use the full name of icon, e.g 'fa-question-circle'  - short name 'question-circle' will not work.
 * Read more on using fontawesome icons in Vue: https://fontawesome.com/docs/web/use-with/vue/add-icons
 */

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faCheckDouble,
  faCircleCheck,
  faUser,
  faUserCircle,
  faTrash,
  faPlus,
  faMinus,
  faEdit,
  faInfoCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronDown,
  faChevronRight,
  faLock,
  faHashtag,
  faArrowLeft,
  faArrowRight,
  faArrowRightLong,
  faLongArrowAltRight,
  faEye,
  faEyeSlash,
  faCircleNotch,
  faTimes,
  faCreditCard,
  faExternalLinkAlt,
  faRedo,
  faSignOut,
  faVideo,
  faSave,
  faAsterisk,
  faSearch,
  faArrowsAlt,
  faSlash,
  faArrowUp,
  faFilter,
  faFilterCircleXmark,
  faGear,
  faTableColumns,
  faStar,
  faUsers,
  faPuzzlePiece,
  faEllipsisVertical,
  faUserGear,
  faRightFromBracket,
  faBriefcase,
  faXmark,
  faPencil,
  faSliders,
  faReply,
  faQuestion,
  faFileExport,
  faInbox,
  faChartSimple,
  faRocket,
  faQuestionCircle,
  faWandMagicSparkles,
  faWrench,
  faFlask,
  faFileArrowUp,
  faFileLines,
  faEnvelope,
  faPaperclip,
  faLink,
  faRefresh,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

import {
  faStar as farStar,
  faQuestionCircle as farQuestionCircle,
  faSave as farSave,
  faComment as farComment,
  faBell as farBell
} from "@fortawesome/free-regular-svg-icons";

import {
  faGithub as fabGithub,
  faGitlab as fabGitlab,
  faJira as fabJira
} from "@fortawesome/free-brands-svg-icons";

function registerLib() {
  library.add(
    faCheck,
    faCheckDouble,
    faCircleCheck,
    faUser,
    faUserCircle,
    faTrash,
    faPlus,
    faMinus,
    faEdit,
    faInfoCircle,
    farQuestionCircle as IconDefinition,
    faExclamationCircle,
    faExclamationTriangle,
    faChevronCircleUp,
    faChevronCircleDown,
    faChevronDown,
    faChevronRight,
    faLock,
    faHashtag,
    faArrowLeft,
    faArrowRight,
    faArrowRightLong,
    faLongArrowAltRight,
    faEye,
    faEyeSlash,
    faCircleNotch,
    faTimes,
    faCreditCard,
    faExternalLinkAlt,
    faRedo,
    faSignOut,
    faVideo,
    faSave,
    farSave as IconDefinition,
    faAsterisk,
    faSearch,
    faArrowsAlt,
    faSlash,
    faArrowUp,
    faFilter,
    faFilterCircleXmark,
    faGear,
    faTableColumns,
    faStar,
    farStar as IconDefinition,
    faUsers,
    faPuzzlePiece,
    fabGithub as IconDefinition,
    fabGitlab as IconDefinition,
    fabJira as IconDefinition,
    faEllipsisVertical,
    faUserGear,
    faRightFromBracket,
    faBriefcase,
    faXmark,
    faPencil,
    faSliders,
    faLink,
    faReply,
    faQuestion,
    faFileExport,
    faInbox,
    faChartSimple,
    faRocket,
    faQuestionCircle,
    faWandMagicSparkles,
    faWrench,
    faFlask,
    faFileArrowUp,
    faFileLines,
    faEnvelope,
    farComment as IconDefinition,
    faPaperclip,
    farBell as IconDefinition,
    faRefresh,
    faPaperPlane
  );
}

// vue3
// import { App } from "@vue/runtime-core";
// export default {
//   install: (app: App /*, options*/) => {
//     app.component("font-awesome-icon", FontAwesomeIcon);
//     registerLib();
//   }
// };

// vue3
import Vue from "vue";
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
registerLib();
