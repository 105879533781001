import { FormViewModel } from "./FormModel";
import { ChannelViewModel } from "./ChannelModel";
import { OptionalKeys } from "@/helpers/ts";

export type InboxIdFormat =
  /**
   * legacy - each form has it's unique queue, Inbox id is responder channel id
   */
  | undefined
  /**
   * new format - many forms can refer to the same queue, Inbox id is queue id
   */
  | "2";

export const INBOX_ID_FORMAT_CHANNEL_ID: InboxIdFormat = undefined;
export const INBOX_ID_FORMAT_QUEUE_ID: InboxIdFormat = "2";
export const OTHER_LEGACY_FAKE_QUEUE_ID = "other-inboxes-legacy";

interface InboxFeatures {
  responderAutoReply?: boolean;
}

export interface InboxModel {
  /**
   * id format depends on format prop and can be:
   *   - responder channnel id
   *   - queue id
   * read more in InboxIdFormat type
   */
  id: string;
  format?: InboxIdFormat;
  /**
   * full id, can be used for router, combines both id and format
   *   - `C.${id}` - for InboxIdFormat = "2"
   *   - `${id}` - otherwise
   */
  key: string;
  name: string;
  channel: ChannelViewModel;
  forms: FormViewModel[];
  /**
   * ISO formatted Created At string
   */
  createdAt?: string;
  features?: InboxFeatures;
}

export type OtherInboxModel = OptionalKeys<InboxModel, "channel">;

export interface OtherInboxModel2 {
  id: string;
  format?: InboxIdFormat;
  /**
   * full id, can be used for router, combines both id and format
   *   - `C.${id}` - for InboxIdFormat = "2"
   *   - `${id}` - otherwise
   */
  key: string;
  name: string;
  /**
   * ISO formatted Created At string
   */
  createdAt?: string;
  totalInboxes?: number;
}

export interface Queue {
  id: string;
  format?: InboxIdFormat;
  name: string;
  createdAt?: string;
  integrationChannels: { channelId: string }[];
  features: InboxFeatures;
}

export function fromQueueIdAndChannelId(
  queueId?: string,
  responderChannelId?: string | null
) {
  // put `C.` prefix for legacy Inbox id format, it will be parsed on the router level
  return queueId == undefined ? `C.${responderChannelId}` : queueId;
}

export function toQueueIdAndChannelId(
  prefixedChannelOrQueueId: string
): { queueId?: string; responderChannelId?: string } {
  return prefixedChannelOrQueueId.startsWith("C.")
    ? {
        responderChannelId: prefixedChannelOrQueueId.replace("C.", "")
      }
    : {
        queueId: prefixedChannelOrQueueId
      };
}
